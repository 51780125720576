const cardContents = [
    {
      title: '児童精神科',
      listBefore: '子どものこころの問題',
      list: [
        '学校に行けない',
        '不安が強い',
        '眠れない',
        '周囲の視線が気になる',
        'パニックになる',
        '気持ちが落ち込む',
        'いらいらする'
      ],
      listAfter: 'などについて診療を行います。'
    },
    {
      title: '成人精神科・心療内科',
      listBefore: '青年期以降のこころの問題',
      list: [
        '気分が落ち込む',
        '不安が強い',
        '眠れない',
        '仕事に行くのがつらい',
        '電車やバスに動悸や不安で乗れない'
      ],
      listAfter: 'などについて診療を行います。',
      text: [
        '必要に応じて、休職や復職についてもきめ細かく相談いたします。'
      ]
    },
    {
      title: '子どもの発達外来',
      listBefore: '発達の特性',
      list: [
        'コミュニケーションが苦手',
        'こだわりが強い',
        '忘れ物が多い',
        '落ち着きがない',
        '学習が困難'
      ],
      listAfter: 'などについて専門的な心理検査を行います。',
      text: [
        '検査結果を参考にしながら、診断、特性の傾向、予測される日常生活の困りごとと具体的な対応策について、ご一緒に考えていきます。',
        '必要に応じて、自閉スペクトラム症の心理教育プログラムなどを行います。'
      ]
    },
    {
      title: '強迫症外来',
      listBefore: '強迫症では',
      list: [
        '手洗いの時間が長くなる',
        '確認することが増え、次の行動に移れない'
      ],
      listAfter: 'といった症状のため、悪化すると生活が困難になることがあります。',
      text: [
        '強迫症を専門とする医師による、児童～成人の強迫症の診療を行っています。',
        '必要に応じて、お薬による治療や認知行動療法を行います。'
      ]
    },
  ]
export default cardContents