import React from 'react'
import * as cardStyle from './card.module.css'

function card(props) {
    let listBlock = ''
    if(props.list) {
        const listItems = props.list.map((ele, index)=>{
            return <li key={index}><span>{ele}</span></li>
        })
        listBlock = (
            <div className={cardStyle.text}>
                <span>{props.listBefore}</span>
                <div className={cardStyle.listWrapper}>
                    <ul className={cardStyle.list}>
                        {listItems}
                    </ul>
                </div>
                <span>{props.listAfter}</span>
            </div>
        )
    }
    let textBlock =''
    if(props.text && props.text.length > 0) {
        textBlock = props.text.map((ele, index)=>{
            return <p className={cardStyle.text} key={index}>{ele}</p>
        })
    }
    let annotationBlock =''
    if(props.annotation) {
        annotationBlock = <p className={cardStyle.annotation}>{props.annotation}</p>
    }
    return (
        <div className={cardStyle.card}>
        <h3 className={cardStyle.title}>{props.title}</h3>
        <div className={cardStyle.body}>
            {listBlock}
            {textBlock}
            {annotationBlock}
        </div>
        </div>
    )
}

export default card



