// extracted by mini-css-extract-plugin
export var consultation = "consultation-module--consultation--RHz0A";
export var consultationInner = "consultation-module--consultation-inner--qsEA7";
export var row = "consultation-module--row--TvRhi";
export var col = "consultation-module--col--4kf2H";
export var heading = "consultation-module--heading--KOQOv default-module--font-bold--5oEvQ";
export var heading_inner = "consultation-module--heading_inner--dPNCu";
export var annotation = "consultation-module--annotation--qaJiK";
export var annotationItem = "consultation-module--annotation-item--JR-8L";
export var interviewSheet = "consultation-module--interview-sheet--aIun3";
export var interviewSheet_title = "consultation-module--interview-sheet_title--owx3P default-module--font-bold--5oEvQ";
export var interviewSheet_titleInner = "consultation-module--interview-sheet_title-inner--3T9m0";
export var interviewSheet_text = "consultation-module--interview-sheet_text--p1+YT";
export var interviewSheet_btn = "consultation-module--interview-sheet_btn--JGJf7";
export var interviewSheet_btnItem = "consultation-module--interview-sheet_btn-item--hOu7W";