import React from 'react'
import { graphql } from 'gatsby'
import Layout from "/src/layout/default"
import Meta from "/src/components/Meta"
import * as style from "./consultation.module.css"
import Card from "/src/components/card"
import cardContents from "/src/data/cardcontents"


function consultation({data, location}) {
  return (
    <Layout location={location}>
        <Meta title="診療のご案内 | 平安堂こころのクリニック" description="平安堂こころのクリニックは、津田沼駅の心療内科・精神科・児童精神科です。専門外来に、子どもの発達外来、強迫症外来を設けています。患者さまに安心して帰っていただけるこころ安らぐクリニックを目指しています。" >
            <meta name="format-detection" content="telephone=no" />
        </ Meta>    

        <div className={style.consultation}>
            <div className={style.consultationInner}>
              <h2 className="page-title"><span className="page-title-inner">診療のご案内</span></h2>
              <div className={style.row}>
                  <div id="consultation1" className={style.col}>
                  <Card {...cardContents[0]} />
                  </div>
                  <div id="consultation2" className={style.col}>
                  <Card {...cardContents[1]} />
                  </div>
              </div>
              <h2 className={style.heading}><span className={style.heading_inner}>専門外来</span></h2>
              <div className={style.row}>
                  <div id="specialty1" className={style.col}>
                  <Card {...cardContents[2]} />
                  </div>
                  <div id="specialty2" className={style.col}>
                  <Card {...cardContents[3]} />
                  </div>
              </div>
              <ul className={style.annotation}>
                  <li className={style.annotationItem}>＊15歳以下のお子様の受診では、十分な診療を行うため、保険診療による自己負担金に加え、初診時のみ予約料5,500円を頂戴しています。</li>
                  <li className={style.annotationItem}>＊療育や４歳以下のお子様の発達についての相談は行っておりません。お住まいの自治体の発達相談センター等にご相談ください。</li>
              </ul>
            </div>
            <div id="interviewSheet" className={style.interviewSheet}>
                <h2 className={style.interviewSheet_title}><span className={style.interviewSheet_titleInner}>問診票</span></h2>
                <p className={style.interviewSheet_text}>初診をご予約いただいた患者様へ<br />問診票を出力して事前にご記入の上、診察当日にお持ちください。ご協力をお願いいたします。</p>
                <p className={style.interviewSheet_btn}><a href={data.pdf.publicURL} target="_blank" rel="noreferrer" className={style.interviewSheet_btnItem}>問診票を出力する</a></p>
            </div>
        </div>
    </ Layout>
  )
}

export default consultation;

export const query = graphql`
  query {
    pdf: file(
      sourceInstanceName: {eq: "static"}
      relativePath: {eq: "files/monshinhyo.pdf"}
    ) {
      publicURL
    }
  }
`